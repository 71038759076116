import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Homepage.css";
import globe from "./Homepage/Images/globe.png";
import thunder from "./Homepage/Images/thunder.png";
import call from "./Homepage/Images/call.png";
import sat from "./Homepage/Images/sat.png";

import i from "./Homepage/Images/send.png";
import lap from "./Homepage/Images/group.png";
import appstore from "./Homepage/Images/appstore.png";
import frame from "./Homepage/Images/Frame.png";
import newbann from "./Homepage/Images/newbann.png";




import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

// import half from "./Homepage/Images/half.png";
import edge from "./Homepage/Images/edge.png";


import { FaChevronRight } from "react-icons/fa";

import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";

// Countdown Timer component
const CountdownTimer = ({
  initialDays = 0,
  initialHours = 0,
  initialMinutes = 1,
  initialSeconds = 0,
}) => {
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else if (minutes === 0 && hours > 0) {
        setHours(hours - 1);
        setMinutes(59);
        setSeconds(59);
      } else if (hours === 0 && days > 0) {
        setDays(days - 1);
        setHours(23);
        setMinutes(59);
        setSeconds(59);
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [days, hours, minutes, seconds]);

  return (
    <div className="countdown-container">
      <h1 className="countdown-heading">Launching In</h1>
      <div className="timer">
        <div className="time-container">
          <span className="time">{days < 10 ? `0${days}` : days}</span>
          <span className="label">Days</span>
        </div>
        <span className="colon">:</span>
        <div className="time-container">
          <span className="time">{hours < 10 ? `0${hours}` : hours}</span>
          <span className="label">Hours</span>
        </div>
       
      </div>
    </div>
  );
};

// Homepage component
const Homepage = () => {
  return (
    <div>
      <div className="homepage-all">
        <Navbar />
        <div className="banner">
          <div className="row ">
            <div className="col-md-8 col-12">
              <div className="banner-des">
                <h1>There is New Era </h1>
                <h2>
                  Stay ahead on Edge in your  Finances with EdgePay{" "}
                </h2>
                <p>
                  Unlock your financial potential with our innovative tools and
                  resources. Whether <br /> you're budgeting, saving, or
                  investing, we 've got you covered every step of the way. <br />{" "}
                  Start your journey towards financial freedom today!
                </p>
                <button type="button">
                <a href="https://dashboard.edgepay.in/page/pay/login?returnTo=%2Fdashboard" target="_blank" rel="noopener noreferrer">

                  Get Started <FaChevronRight />
                  </a>
                </button>
              </div>
            </div>
            <div className="col-md-4 col-12" style={{ overflow: "visible" }}>
            <img src={newbann} alt="Edge" className="img-fluid  h-100 mt-4 pe-5 object-fit-cover" style={{ overflow: "visible" }} />
            </div>
          </div>
        </div>
        <div className="launching-sec">
          <CountdownTimer
            initialDays={10}
            initialHours={1}
            initialMinutes={30}
            initialSeconds={45}
          />
        </div>
        <div className="white-line">

       
        <div className="timer-des py-5">
          <div className="row container mx-auto">
            <div className="col-md-6 col-12">
              <p>
                ARE YOU STILL CONFUSED <br />
                ABOUT YOUR PAYMENTS AND <br />
                FINANCES? EDGE IS HERE <br />
                FOR YOU
              </p>
            </div>
            <div className="col-md-6 col-12  d-flex justify-content-center align-items-center">
              <h2>
                Edge is Here To Help You <br />
                With Payments And Finances.  <br />
                We Are There For You!
              </h2>
            </div>
          </div>
        </div>
        <div className="iphone py-5">
          <div className="row container g-0 mx-auto">
            <div className="col-md-4 col-12 pe-0 me-0">
              <img src={i} alt="Edge" className="img-fluid float-end pe-3" />
            </div>
            <div className="col-md-8 col-12">
              <div className="iphone-des">
                <p className="service-des " >
                  <span>Edge Helps </span> The Problem You Are Facing
                </p>
                <div className="i-card">
                  <div className="num">
                    <span>1</span>
                  </div>
                  <div className="i-card-des">
                    <h2>💸 You Are not charged taxes for each transaction</h2>
                    <p>
                      Get more money, you will not be charged fees or taxes for
                      the transactions you make. it will be right for everyone
                    </p>
                  </div>
                  <div className="info">
                    {/* <IoIosInformationCircleOutline /> */}
                    <img src={frame} alt="Edge" className="img-fluid" />

                  </div>
                </div>
                <div className="i-card">
                  <div className="num">
                    <span>2</span>
                  </div>
                  <div className="i-card-des">
                    <h2>🥷🏼 Transaction Without Worry, Easily And Safely</h2>
                    <p>
                      We use the latest technology, we always maintain the
                      security of transactions that are carried out on our
                      service
                    </p>
                  </div>
                  <div className="info">
                  <img src={frame} alt="Edge" className="img-fluid" />
                  </div>
                </div>
                <div className="i-card">
                  <div className="num">
                    <span>3</span>
                  </div>
                  <div className="i-card-des">
                    <h2>💼 Suitable For Ordinary People To Companies</h2>
                    <p>
                      Are you a civil servant or a business actor? Yep, you came
                      to the right platform, Edge can solve problems
                    </p>
                  </div>
                  <div className="info">
                  <img src={frame} alt="Edge" className="img-fluid" />
                  </div>
                </div>
                <div className="i-card">
                  <div className="num">
                    <span>4</span>
                  </div>
                  <div className="i-card-des">
                    <h2>🏛️ Vendor Payouts: Seamless Transactions, Secure and Simple</h2>
                    <p>
                    In fintech, a vendor payout refers to the automated or digital disbursement of funds from a business to a supplier or service provider
                    </p>
                  </div>
                  <div className="info">
                  <img src={frame} alt="Edge" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="half-iphone py-5">
          <div className="row container mx-auto">
            <div className="col-md-7 col-12">
              <div className="half-i-des">
                <h2>
                  Why Do You <br />
                  Have To Choose <br />
                  And Use Edge?
                </h2>
                <div className="transactions">
                  <div className="transaction-col">
                  <img src={globe} alt="Edge" className="img-fluid" />
                    <h3>Cross-border transactions</h3>
                    <p>
                      Make transactions around the <br />
                      world quickly and safely
                    </p>
                  </div>
                  <div className="transaction-col">
                  <img src={thunder} alt="Edge" className="img-fluid" />
                    <h3>Fast and easy transactions</h3>
                    <p>
                      Edge prioritizes the satisfaction <br />
                      of our users. Try Edge now
                    </p>
                  </div>
                </div>
                <div className="transactions py-3">
                  <div className="transaction-col">
                  <img src={call} alt="Edge" className="img-fluid" />

                    <h3>24 hours assistance</h3>
                    <p>
                      we are 24 hours available to help <br />
                      you. We are there for you
                    </p>
                  </div>
                  <div className="transaction-col">
                    {/* <FaHandHoldingHeart className="globe-h" /> */}
                    <img src={sat} alt="Edge" className="img-fluid " />

                    <h3>Your satisfaction is our priority</h3>
                    <p>
                      We always put the happiness of our users first <br />
                      Edge Finance
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="half-con">
                <div className="half-img">
                  <div className="half-img-des">
                    <div className="logo-sec-des">
                      <img src={edge} alt="Edge" className="img-fluid" />
                      <p>Today, 20 Mins</p>
                    </div>
                    <h2>₹500 of money from Edge goes to your account</h2>
                    <p>
                      Rafi Alfando has transferred ₹540 to your account. With
                      transaction number #AH43530802 you can check on Edge
                      website
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="lap-sec">
          <h1>
            Stay on Edge On Our Web App <br /> or Mobile App
          </h1>
          <div className="lap-img container">
            <img src={lap} alt="Edge" className="img-fluid" />
          </div>
          <div className="peoples pb-5">
            <div className="row container mx-auto">
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>32k</h3>
                  <h2>More than 32k people use Edge</h2>
                  <p>
                    More than 32k people have used our <br /> services, many
                    have used Ahlan
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>10k+</h3>
                  <h2>10k+ people download the app</h2>
                  <p>
                    more than 10k+ people download the <br /> app and try it.
                    You have to try
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>40k+</h3>
                  <h2>40k+ people helped</h2>
                  <p>
                    40k+ people have been helped by Edge's <br /> services, we
                    are ready to help you
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-app py-5 mt-5 ">
            <div className="row mobile-shade container mx-auto">
           

              <div className="col-md-6 col-12">
              <div className="soon-all">
                <p>
                  Stay on Edge with <br /> EdgePay
                </p>
                <div className="soon">
                  <button className="play-store">
                    <FaGooglePlay className="store-icon" />
                    <h3>Coming Soon</h3>
                  </button>

                  <button className="play-store">
                    <FaApple className="store-icon" />
                    <h3>Coming Soon</h3>
                  </button>
                </div>
                </div>

              </div>
              <div className="col-md-6 col-12">
                <div className="app-imgg">
                <img src={appstore} alt="Edge" className="img-fluid" />
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
